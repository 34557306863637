<template>
  <div class="card-box">

    <div class="form">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="诊断医生" name="0"></el-tab-pane>
        <el-tab-pane label="历史诊断" name="1"></el-tab-pane>
      </el-tabs>

      <el-form
          :inline="true"
          :rules="rules"
          ref="form"
          label-width="80px"
          v-if="activeName == 0"
          :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="报告类型" prop="reportType">
              <el-select v-model="form.reportType" clearable placeholder="请选择报告类型">
                <el-option v-for="item in $store.state.reportType" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="患者姓名" prop="patientName">
              <el-input v-model="form.patientName" clearable placeholder="请输入患者姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="手机号" prop="phoneNo">
              <el-input v-model="form.phoneNo" clearable placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-form
          :inline="true"
          :rules="rules"
          ref="form"
          label-width="80px"
          v-if="activeName == 1"
          :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="报告类型" prop="reportType">
              <el-select v-model="form.reportType" clearable placeholder="请选择报告类型">
                <el-option v-for="item in $store.state.reportType" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="患者姓名" prop="patientName">
              <el-input v-model="form.patientName" clearable placeholder="请输入患者姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="手机号" prop="phoneNo">
              <el-input v-model="form.phoneNo" clearable placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="原因" prop="reason">
              <el-input v-model="form.reason" clearable placeholder="请输入原因"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="诊断建议" prop="diagnosis">
              <el-input v-model="form.diagnosis" clearable placeholder="请输入诊断建议"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </div>


    <div class="card-content"
         :style="{ minHeight : ( activeName == 0 ? 'calc(100vh - 278px)' : 'calc(100vh - 340px)' ) }"
         style="margin-top: 12px">

      <template v-if="list.length != 0">
        <el-row :gutter="100">
          <el-col :span="6" v-for="item in list" :key="item.orderId" >
            <div class="card"
                 @click="linkDeta(item.id)"
                 style="cursor: pointer"
                 :class="[
                   ( item.reportType == 0 ?
                  'DarkBlue' : ( item.reportType == 1 ?
                   'LightBlue' : ( item.reportType == 2 ?
                    'LightGreen' : 'Violet' ) ))
               ]">

              <div class="card-header">
                <div class="card-header-left">
                  <p>{{ $store.state.reportType.filter((items) => { return items.dictKey == item.reportType })[0].dictValue }}</p>
                </div>
                <div class="card-header-right">
                  <p v-if="item.diagnosisStatus == 1" >已诊断</p>
                  <p v-if="item.diagnosisStatus == 2" >全部完成</p>
                  <p v-else>未诊断</p>
                </div>
              </div>

              <div class="card-item">
                <el-tooltip effect="dark" :content="item.name" placement="top">
                  <p>{{ item.name }}</p>
                </el-tooltip>
                <p>{{ item.gender == 0 ? "女" : "男" }}</p>
                <p>{{ item.phoneNo }}</p>
              </div>

              <div class="card-footer">

                <div class="card-footer-left">
                  <!--                  <p class="card-footer-left-time" ></p>-->
                </div>

                <div class="card-footer-right">
                  <p>随访诊断</p>
                </div>

              </div>

            </div>
          </el-col>
        </el-row>

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[12, 24, 48, 96]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </template>

      <template v-else>
        <NoList>
          <template #text>
            <p>暂无数据</p>
          </template>
        </NoList>
      </template>

    </div>

  </div>
</template>

<script>
export default {
  name: "Doctor",
  data() {
    return{
      activeName: '0',
      form: {
        pageNum: 1,
        pageSize: 12,
        reportType: null,
        patientName: null,
        phoneNo: null,
        reason: null,
        diagnosis: null,
      },
      rules: {
        phoneNo: [
          {pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '手机号不正确'}
        ]
      },
      list: [],
      total: 0,
    }
  },
  created() {

    if(this.$route.query.activeName != null) {
      this.activeName= this.$route.query.activeName;
    }

    this.get_list(this.form);
  },
  mounted() {

  },
  methods: {
    linkDeta(e) {
      this.$router.push({
        path: "/DoctorDeta",
        query: {
          id: e,
          state: this.activeName
        }
      })
    },
    onSubmit(formName) {
      this.$refs.form.validate((valid) => {
        if(valid) {
          console.log(formName)

          formName.pageNum= 1;
          this.get_list(formName);
        }
      })
    },
    // 查询
    get_list(y) {
      if(this.activeName == 0) {
        this.$get("diagnosis/page",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.total= res.data.total;
                this.list= res.data.list;
              }
            })
      }else if(this.activeName == 1) {
        this.$get("diagnosis/history",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.total= res.data.total;
                this.list= res.data.list;
              }
            })
      }
    },
    handleCurrentChange(e) {
      console.log(`第${e}页`);
      this.form.pageNum= e;
      this.get_list(this.form);
    },
    handleSizeChange(e) {
      console.log(`每页${e}条`);
      this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },
    handleClick() {
      this.form.pageNum= 1;
      this.form.pageSize= 8;
      this.$refs["form"].resetFields();

      this.get_list(this.form);

    },
  }
}
</script>

<style lang="scss" scoped>
  //@import "../../style/Doctor/Doctor";
  @import "../../style/Service/card";
</style>
